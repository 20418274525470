import { h, type Component } from 'vue'
import dayjs from 'dayjs'
import { cloneDeep, difference, find, findIndex, map, isNil } from 'lodash-es'

import { calculator } from '@enocloud/utils'
import { EnMessage, EnMessageBox, type TableColumnCtx } from '@enocloud/components'
import { type GoodsSelectionOption } from '@enocloud/business'

import { type DiscountRes } from '@service/components/workorder-discount.vue'

export const formMaintenanceInit = (
  props: Partial<EnocloudServiceDefinitions['ServiceMaintenanceDto']> = {}
): EnocloudServiceDefinitions['ServiceMaintenanceDto'] => {
  const maintenance = { internalLaborHour: 0, laborHour: 1, assignees: [] as any } as EnocloudServiceDefinitions['ServiceMaintenanceDto']
  return Object.assign(maintenance, props)
}

const formGoodsInit = <T extends EnocloudServiceDefinitions['ServiceGoodsDto']>(props: Partial<T>): T => {
  const goods = { price: 0, planCount: 1 } as T
  return Object.assign(goods, props)
}

const chargingMethodInit = (code: string | undefined): EnocloudServiceDefinitions['LookupDto'] => {
  if (code === 'REWORK') return { code: 'CLM', message: '索赔', description: '', type: '' }
  if (code === 'ACCIDENT') return { code: 'INS', message: '一般保险', description: '', type: '' }
  else return { code: 'CLT', message: '自费维修', description: '', type: '' }
}

export const workorder = ({ components }: { components: Record<string, Component> }) => {
  return factory({
    async mounted() {
      const fields = (await this.ajax('GET /enocloud/form/fields/setting', { payload: { formFieldsType: 'SD' } })).data

      for (const field of fields) {
        switch (field.serviceFormFieldsType?.code) {
          case 'SERVICE_SUGGESTIONS':
            this.form.suggestions.show = field.shown?.value
            this.form.suggestions.required = field.required?.value
            break
        }
      }
    },

    page: true,

    components,

    config: {
      children: {
        operation: {
          add: {
            confirm(id?: number) {
              this.form.data.id ??= id
              this.form.get()
            }
          },
          save: {
            async click() {
              await this.form[this.form.isFake ? 'updateFake' : 'submit']()
              return this.form.get()
            }
          },
          return: {
            async click() {
              switch (this.form.data.status?.code) {
                case 'PD':
                  this.form.data.lastStep = { code: 'IM', description: '', message: '', type: '' }
                  break
              }
              await this.form.submit()
              this.refs.mainfest.table.refresh()
              return this.form.get()
            }
          },
          option: {
            async command(option: string) {
              await this.dirtyCheck('form')
              switch (option) {
                case 'workorder':
                  this.detail.visible = true
                  break
                case 'customer':
                  this.customerRecord.visible = true
                  break
                case 'vehicle':
                  this.vehicleRecord.visible = true
                  break
                case 'rework-order':
                  this.reworkOrder.visible = true
                  break
                case 'logs':
                  this.logs.visible = true
                  break
                case 'maintain':
                  this.maintain.visible = true
                  break
                case 'fake':
                  if (this.form.data.fakeService?.id) {
                    this.form.data.id = this.form.data.fakeService?.id
                    this.form.get()
                  } else {
                    const res = await this.form.submitFake({ paths: [this.form.data.id], payload: this.form.data })
                    this.form.data.id = res.data[0] as number
                    this.form.get()
                  }
                  break
                case 'coupon':
                  this.coupon.visible = true
                  break
                case 'mall':
                  if (this.store.accessRightsHash.MALL_COMBO_ORDER_QUERY) {
                    this.mall.visible = true
                  } else {
                    EnMessage.warning('您当前无权限查看套餐信息')
                  }
                  break
                case 'top-up':
                  this.topup.visible = true
                  break
                case 'deposit':
                  this.deposit.visible = true
                  break
                case 'blance':
                  this.blance.visible = true
                  break
                case 'discard':
                  {
                    const res = await EnMessageBox.prompt('备注', '提示', {
                      confirmButtonText: '确定',
                      cancelButtonText: '取消',
                      inputValidator: (value) => {
                        return value !== null && value !== ''
                      },
                      inputErrorMessage: '请填写取消工单的原因'
                    })
                    await this.form.discard({ addition: { id: this.form.data.id, comment: res.value } })
                    this.form.get()
                  }
                  break
                case 'qrcode':
                  {
                    const res = await this.form.bind()
                    return EnMessageBox({
                      title: '微信绑定',
                      center: true,
                      showConfirmButton: false,
                      message: h('img', { src: res.data[0], width: 300, height: 300, class: 'm-auto' })
                    })
                  }
                  break
                case 'elecdoc':
                  {
                    const res = await this.ajax('GET /enocloud/elecdoc')
                    if (!res.data[0]?.enabled?.value) return EnMessage.warning('电子档案上传未开启')
                    await this.form.elecdoc()
                    this.form.get()
                  }
                  break
                case 'material':
                  this.router.push('/accessory/inventory/requestion', (vm) => {
                    vm.form.data.id = this.form.data.id
                    vm.form.get()
                  })
                  break
              }
            }
          },
          back: {
            async click() {
              await this.dirtyCheck('form')
              this.form.data.id = this.form.data.originalServiceId
              this.form.get()
            }
          }
        },
        manifest: {
          config: {
            OPERATION: { visible: false }
          },
          row: {
            click(row: EnocloudServiceDefinitions['ServiceQueryDto']) {
              this.form.init()
              this.form.data.id = row.id
              this.form.get()
            }
          },
          quotation: {
            click(row: EnocloudServiceDefinitions['ServiceQueryDto']) {
              if (!row.vehicleServiceGroup?.serviceQuotationId) return
              this.router.push('/service/business/quotation', (vm) => {
              
                vm.form.data.id = row.vehicleServiceGroup?.serviceQuotationId
                vm.form.get()
              })
            }
          },
          inspection: {
            click(row: EnocloudServiceDefinitions['ServiceQueryDto']) {
              if (!row.vehicleServiceGroup?.vehicleInspectionId) return
              this.router.push('/service/business/quotation', (vm) => {
                vm.form.data.id = row.vehicleServiceGroup?.vehicleInspectionId
                vm.form.get()
              })
            }
          }
        },
        tabs: {
          active: 'basic',
          children: {
            settlement: {
              async click() {
                const { currentMileage, suggestions } = this.form.data
                if (this.form.suggestions.show && !suggestions) {
                  this.tabs.active = 'inspection'
                  return EnMessage.warning('请填写维修建议')
                }
                if (!currentMileage) {
                  this.tabs.active = 'inspection'
                  return EnMessage.warning('请填写进厂里程')
                }

                this.form.data.nextStep = { code: 'PD', message: '', type: '', description: '' }

                if (this.form.document?.id) {
                  await this.form.submit()
                  this.form.get()
                } else {
                  await EnMessageBox.confirm('是否结算！', '提示', { type: 'warning' })
                  const res: any = await this.form.submit({ config: { ignores: ['INVALID_SERVICE_NEED_TO_APPROVAL'] } })
                  this.form.get()
                  if (find(res.warnings, ['code', 'INVALID_SERVICE_NEED_TO_APPROVAL'])) {
                    this.approval.visible = true
                  }
                }

                this.refs.mainfest.table.refresh()
              }
            }
          }
        },
        form: {
          ajax: {
            get: {
              action: 'GET /enocloud/service/:serviceId',
              data: 'object',
              init: true,
              loading: true,
              dirty: true,
              params(params) {
                params.paths = [this.form.data.id]
              },
              convert(data: EnocloudServiceDefinitions['ServiceDto']) {
                const mileage = Number(this.store.attribute.MATMIL.value)
                data.nextMaintenanceMileage = data.vehicle?.nextMaintenanceMileage || calculator.add(mileage, data.currentMileage, 2)
                return data
              }
            },
            submit: {
              action: 'PUT /enocloud/service',
              loading: true,
              params(params) {
                params.payload = this.form.data
                params.payload.maintenances = params.payload.maintenances?.filter((item) => item.name || item.maintenance?.id)
                params.payload.goods = params.payload.goods?.filter((item) => item.name || item.goodsSpecification?.id)
              }
            },
            discard: {
              action: 'POST /enocloud/service/:serviceId/discard',
              loading: true,
              params(params) {
                params.paths = [this.form.data.id]
              }
            },
            bind: {
              action: 'GET /enocloud/common/customer/:customerId/wechat/bind/url',
              params(params) {
                params.paths = [this.form.data.customer?.id]
              }
            },
            elecdoc: {
              action: 'POST /enocloud/elecdoc/service/:serviceId',
              params(params) {
                params.paths = [this.form.data.id]
              }
            },
            submitFake: {
              action: 'POST /enocloud/service/:serviceId/fake',
              loading: true
            },
            updateFake: {
              action: 'PUT /enocloud/service/fake',
              loading: true,
              params(params) {
                params.payload = this.form.data
              }
            }
          },
          computed: {
            disabled() {
              return ['PD', 'DC'].includes(this.form.data.status?.code ?? '')
            },
            document() {
              return this.form.data.taskDocuments?.find((item) => item.taskType?.code === 'SDSCAPL' && item.progress?.code === 'SDCRATP')
            },
            hasFullCarPaints() {
              const spraySurfaceCodes = this.form.data.maintenances?.map((item) => item.maintenance?.spraySurface) ?? []
              return spraySurfaceCodes.includes('WSFC') || spraySurfaceCodes.includes('WMFC')
            },
            checkPercent() {
              let index = 5
              if (this.form.data.nextMaintenanceDate) index++
              if (this.form.data.things?.length) index++
              if (this.form.data.descriptions?.length) index++
              if (this.form.data.solution) index++
              if (this.form.data.comment) index++
              return calculator.mul(calculator.div(index, 10), 100, 0) + '%'
            },
            maintenancesForFee() {
              return (this.form.data.maintenances ?? []).filter((item) => item.chargingMethod?.includedFee)
            },
            goodsForFee() {
              return (this.form.data.goods ?? []).filter((item) => item.chargingMethod?.includedFee)
            },
            maintenanceFee() {
              // 工时费=各个维修项目的折前金额和
              const fee = this.form.maintenancesForFee.reduce(
                (result, item) => {
                  const discountAmount = calculator.mul(item.amountBeforeDiscount, calculator.sub(1, item.discountRate), 2)
                  result.laborHour = calculator.add(item.laborHour, result.laborHour, 2)
                  result.amountBeforeDiscount = calculator.add(result.amountBeforeDiscount, item.amountBeforeDiscount, 2)
                  result.amount = calculator.add(result.amount, calculator.sub(item.amountBeforeDiscount, discountAmount), 2)
                  result.discountAmount = calculator.add(result.discountAmount, discountAmount)
                  return result
                },
                { laborHour: 0, amountBeforeDiscount: 0, amount: 0, discountRate: 0, discountAmount: 0 }
              )
              fee.discountRate = fee.amount > 0 ? calculator.div(fee.amount, fee.amountBeforeDiscount) : 1
              return { ...fee }
            },
            goodsFee() {
              const fee = this.form.goodsForFee.reduce(
                (result, item) => {
                  const discountAmount = calculator.mul(item.amountBeforeDiscount, calculator.sub(1, item.discountRate), 2)
                  result.count = calculator.add(result.count, item.count)
                  result.amountBeforeDiscount = calculator.add(result.amountBeforeDiscount, item.amountBeforeDiscount, 2)
                  result.amount = calculator.add(result.amount, calculator.sub(item.amountBeforeDiscount, discountAmount), 2)
                  result.discountAmount = calculator.add(result.discountAmount, discountAmount)
                  return result
                },
                { amountBeforeDiscount: 0, amount: 0, discountRate: 0, discountAmount: 0, count: 0 }
              )
              fee.discountRate = calculator.div(fee.amount, fee.amountBeforeDiscount)
              return { ...fee }
            },
            serviceFee() {
              const {
                maintenances = [],
                managementFee = 0,
                managementFeeRate = 0,
                tax = 0,
                taxRate = 0,
                otherAmount = 0,
                autoEraseAmount = 0,
                receivableAmount = 0,
                receivedAmount = 0,
                discount = 0,
                couponInstancesAmount = 0,
                actualOutput = 0,
                expenseAmount = 0,
                memberAmount = 0
              } = this.form.data

              /** 折前维修总费用 (折前工时费 + 折前配件费 + 配件管理费 + 其他 + 税金) */
              const amountBeforeDiscount = calculator.add(
                calculator.add(
                  calculator.add(
                    calculator.add(this.form.maintenanceFee.amountBeforeDiscount, this.form.goodsFee.amountBeforeDiscount),
                    managementFee
                  ),
                  otherAmount
                ),
                tax
              )

              /** 折后维修总费用 (折后工时费 + 折后配件费 + 配件管理费 + 其他 + 税金) */
              const amountAfterDiscount = calculator.add(
                calculator.add(
                  calculator.add(calculator.add(this.form.maintenanceFee.amount, this.form.goodsFee.amount), managementFee),
                  otherAmount
                ),
                tax
              )

              let _autoEraseAmount = autoEraseAmount

              /** 未抹零的工单合计 */
              const amountBeforeAutoEraseAmount = calculator.sub(calculator.sub(amountAfterDiscount, discount), couponInstancesAmount)

              if (this.store.attribute.SRVAOTERS.value === 'Y' && receivableAmount > 0) {
                _autoEraseAmount = autoEraseAmount > 0 ? autoEraseAmount : calculator.sub(receivableAmount, parseInt(receivableAmount.toFixed(2)), 2)
              }

              return {
                amountBeforeDiscount,
                /** 工单合计 (折前维修总费用 - 工单减免 - 卡券优惠 - 抹零) */
                amount: calculator.sub(calculator.sub(calculator.sub(amountBeforeDiscount, discount), couponInstancesAmount), _autoEraseAmount),
                /** 配件管理费 */
                managementFee,
                /** 配件管理费率 */
                managementFeeRate,
                /** 税金 */
                tax,
                /** 税金比率 */
                taxRate,
                /** 其他费用 */
                otherAmount,
                /** 抹零 (折后工时费+折后配件费+配件管理费+税金+辅料费+其他费-卡券抵扣-工单减免) */
                autoEraseAmount: _autoEraseAmount,
                /** 工单积分 */
                memberAmount,
                /** 业务支出 */
                expenseAmount,
                /** 实际产值 */
                actualOutput,
                /** 工单减免 */
                discount,
                /** 卡券优惠 */
                couponInstancesAmount,
                /** 应收款 */
                receivableAmount,
                /** 已收款 */
                receivedAmount,
                /**优惠减免(坏账) */
                receivableBadDebt: Math.abs(this.form.data.receivableBadDebt ?? 0),
                /** 未抹零的工单合计 (维修总费用 - 工单减免 - 卡券优惠)*/
                amountBeforeAutoEraseAmount,
                /** 索赔返修 */
                repareAmount:
                  maintenances
                    ?.filter((item) => item.chargingMethod?.code && ['CLM', 'RWK'].includes(item.chargingMethod.code))
                    .reduce((amount, item) => calculator.add(amount, item.amountBeforeDiscount ?? 0), 0) ?? 0
              }
            },
            isFake() {
              return this.form.data.fake?.value
            }
          },
          children: {
            currentMileage: {
              blur() {
                this.form.data.currentMileage ??= 0
                if (this.form.data.lastTimeService?.currentMileage && this.form.data.currentMileage < this.form.data.lastTimeService.currentMileage) {
                  return EnMessageBox.confirm('本次输入的进厂里程小于上次进厂里程！', '提示', { confirmButtonText: '关闭', showCancelButton: false })
                }

                if (this.form.data.maintenanceFlag?.value) {
                  this.form.data.nextMaintenanceMileage = calculator.add(Number(this.store.attribute.MATMIL.value), this.form.data.currentMileage, 2)
                  this.form.data.nextMaintenanceDate = dayjs().add(Number(this.store.attribute.MATINT.value), 'month').format('YYYY-MM-DD')
                }
              },
              select(value: string) {}
            },
            nextMaintenanceMileage: {
              value: '',
              select() {
                let mileage = parseInt(this.form.nextMaintenanceMileage.value) || 0
                if (!mileage && this.form.data.maintenanceFlag?.value) {
                  mileage = Number(this.store.attribute.MATMIL.value)
                }
                const final = calculator.add(this.form.data.currentMileage, mileage, 0)
                if (this.form.data.vehicle) this.form.data.vehicle.nextMaintenanceMileage = final
                this.form.data.nextMaintenanceMileage = final
              },
              change(value: number) {
                if (this.form.data.maintenanceFlag?.value) {
                  if (this.form.data.vehicle) {
                    this.form.data.vehicle.nextMaintenanceMileage = value
                  }
                } else {
                  this.form.data.nextMaintenanceMileage = value
                }
              }
            },
            maintenanceFlag: {
              change() {
                if (this.form.data.maintenanceFlag?.value) {
                  this.form.data.nextMaintenanceMileage = calculator.add(Number(this.store.attribute.MATMIL.value), this.form.data.currentMileage, 2)
                  this.form.data.nextMaintenanceDate = dayjs().add(Number(this.store.attribute.MATINT.value), 'month').format('YYYY-MM-DD')
                }
              }
            },
            maintenances: {
              summaryMethod<T extends EnocloudServiceDefinitions['ServiceMaintenanceDto'], K extends keyof T>(ctx: {
                columns: TableColumnCtx<T>[]
                data: T[]
              }) {
                return ctx.columns
                  .map((item) => item.property)
                  .map((key, index) => {
                    if (['laborHour', 'internalLaborHour', 'amountBeforeDiscount'].includes(key)) {
                      return `${ctx.data.reduce((total, d) => calculator.add(total, d[key as K] as number), 0)}`
                    } else return !index ? '合计' : '-'
                  })
              },
              operation: {
                delete: {
                  click(index: number) {
                    this.form.data.maintenances?.splice(index, 1)
                  }
                },
                edit: {
                  click(row: EnocloudServiceDefinitions['ServiceMaintenanceDto']) {
                    this.maintenanceEdit.form.data = formMaintenanceInit()
                    this.maintenanceEdit.form.data = { ...row }
                    this.maintenanceEdit.visible = true
                  }
                },
                add: {
                  click() {
                    this.form.maintenances.add({ name: '' })
                  }
                },
                inflated: {
                  click() {
                    this.form.maintenances.add({ name: 'X' }, { inflated: true })
                  }
                },
                change(value: EnocloudCommonDefinitions['MaintenanceDto']) {
                  this.form.maintenances.add({ maintenance: value, name: value.name })
                },
                spray: {
                  click() {
                    this.spraySurface.data.maintenances = this.form.data.maintenances?.filter((item) => !!item.maintenance?.spraySurface) ?? []
                    this.spraySurface.data.codes = this.spraySurface.data.maintenances.map((item) => item.maintenance!.spraySurface!) ?? []
                    this.spraySurface.visible = true
                  }
                },
                command(option: string) {
                  if (!this.form.maintenances.selection.data.length) return EnMessage.warning('请选择维修项目')
                  if (option === 'chargingMethod') {
                    this.chargingMethodBatch.type = 'maintenance'
                    this.chargingMethodBatch.visible = true
                  } else if (option === 'assignee') {
                    this.workingAssigneeBatch.type = 'maintenance'
                    this.workingAssigneeBatch.visible = true
                  }
                },
                outsource: {
                  click(row: EnocloudServiceDefinitions['ServiceMaintenanceDto']) {
                    this.outsource.data = formMaintenanceInit(row)
                    this.outsource.visible = true
                  }
                }
              },
              add(props: Partial<EnocloudServiceDefinitions['ServiceMaintenanceDto']>, options: { inflated?: boolean } = {}) {
                const { inflated } = options
                const { customer, maintenances } = this.form.data
                const { maintenanceFee } = this.form

                const init = formMaintenanceInit({
                  chargingMethod: chargingMethodInit(this.form.data.serviceCategory?.type?.code),
                  inflatedFlag: { code: inflated ? 'Y' : 'N', message: '', type: '', description: '', inflated },
                  price: this.store.attribute.SVMTVLM.value === 'H' ? Number(this.store.attribute.SVMLBHPRC.value) : 0,
                  valuationMethod: { code: this.store.attribute.SVMTVLM.value, description: '', message: '', type: '' },
                  ...props
                })

                init.discountRate = maintenances?.length
                  ? calculator.div(maintenanceFee.amount, maintenanceFee.amountBeforeDiscount, 2)
                  : customer?.serviceMaintenanceDiscountRate ?? 1

                this.form.maintenances.cal(init)

                this.form.data.maintenances?.unshift(init)

                for (const row of this.form.data.maintenances ?? []) {
                  this.refs.formMaintenancesTable.toggleRowSelection(row, !row.workingTeam?.id)
                }
              },
              cal(row: EnocloudServiceDefinitions['ServiceMaintenanceDto']) {
                row.amountBeforeDiscount = calculator.mul(row.price, row.laborHour)
                return row
              },
              selection: {
                data: [] as EnocloudServiceDefinitions['ServiceMaintenanceDto'][],
                change(rows: EnocloudServiceDefinitions['ServiceMaintenanceDto'][]) {
                  this.form.maintenances.selection.data = rows
                }
              },
              laborHour: {
                change(row: EnocloudServiceDefinitions['ServiceMaintenanceDto']) {
                  this.form.maintenances.cal(row)
                }
              },
              price: {
                change(row: EnocloudServiceDefinitions['ServiceMaintenanceDto']) {
                  this.form.maintenances.cal(row)
                }
              },
              valuationMethod: {
                change(row: EnocloudServiceDefinitions['ServiceMaintenanceDto']) {
                  if (row.valuationMethod?.code === 'P') row.laborHour = 1
                  this.form.maintenances.cal(row)
                }
              },
              chargingMethod: {
                change(row: EnocloudServiceDefinitions['ServiceMaintenanceDto']) {
                  row.discountRate = row.chargingMethod?.forceDiscountRate
                    ? row.chargingMethod.defaultDiscountRate
                    : this.form.data.customer?.serviceGoodsDiscountRate
                }
              },
              workingTeam: {
                change(row: EnocloudServiceDefinitions['ServiceMaintenanceDto']) {
                  row.assignees = []
                }
              },
              assignees: {
                change(row: EnocloudServiceDefinitions['ServiceMaintenanceDto'], value: Array<string | EnocloudServiceDefinitions['UserDto']>) {
                  row.assignees = value.map((item) => {
                    if (typeof item === 'string') {
                      return { name: item }
                    } else {
                      return {
                        id: row.assignees.find((a) => a.assignee?.id === item.id)?.id,
                        name: row.assignees.find((a) => a.assignee?.id === item.id)?.name,
                        assignee: item
                      }
                    }
                  })
                }
              },
              inflatedFlag: {
                change(row: EnocloudServiceDefinitions['ServiceMaintenanceDto']) {},
                clear(row: EnocloudServiceDefinitions['ServiceMaintenanceDto']) {}
              }
            },
            goods: {
              operation: {
                delete: {
                  click(index: number) {
                    this.form.data.goods?.splice(index, 1)
                  }
                },
                add: {
                  click() {
                    this.form.goods.add({})
                  }
                },
                inflated: {
                  click() {
                    this.form.goods.add({}, { inflated: true })
                  }
                },
                batch: {
                  click() {
                    this.goodsSelect.visible = true
                  }
                },
                change(value: EnocloudCommonDefinitions['GoodsDto']) {
                  this.form.goods.add({
                    goodsSpecification: {
                      ...value.specifications?.find((item) => item.defaultService?.value)!,
                      goods: cloneDeep(value)
                    } as EnocloudServiceDefinitions['GoodsSpecificationDto']
                  })
                }
              },
              add(props: Partial<EnocloudServiceDefinitions['ServiceGoodsDto']>, options: { inflated?: boolean } = {}) {
                const { inflated } = options

                const init = formGoodsInit({
                  chargingMethod: chargingMethodInit(this.form.data.serviceCategory?.type?.code),
                  inflatedFlag: { code: inflated ? 'Y' : 'N', message: '', type: '', description: '', inflated },
                  price: props.goodsSpecification?.goods?.servicePrice ?? 0,
                  inventoryCount: props.goodsSpecification?.goods?.batches.reduce((count, item) => calculator.add(count, item.count), 0),
                  ...props
                })

                this.form.goods.cal(init)
                this.form.data.goods?.unshift(init)
              },
              cal(row: EnocloudServiceDefinitions['ServiceGoodsDto']) {
                row.amountBeforeDiscount = calculator.mul(row.count, row.price)
                return row
              },
              selection: {
                data: [] as EnocloudServiceDefinitions['ServiceGoodsDto'][],
                change(rows: EnocloudServiceDefinitions['ServiceGoodsDto'][]) {
                  this.form.goods.selection.data = rows
                }
              },
              serialNo: {
                change(row: EnocloudServiceDefinitions['ServiceGoodsDto'], value: EnocloudServiceDefinitions['GoodsDto']) {
                  const { specifications } = value
                  const goodsSpecification = find(specifications, ['defaultService.value', true]) || specifications?.[0]
                  goodsSpecification!.goods = cloneDeep(value)
                  row.goods = value
                  row.goodsSpecification = goodsSpecification
                  row.price = goodsSpecification!.servicePrice
                  row.inventoryCount = row.goodsSpecification?.goods?.batches.reduce((count, item) => calculator.add(count, item.count), 0)
                }
              },
              count: {
                change(row: EnocloudServiceDefinitions['ServiceGoodsDto']) {
                  this.form.goods.cal(row)
                },
                tooltip(row: EnocloudServiceDefinitions['ServiceGoodsDto'], code: string | undefined) {
                  return `${row.histories.find((h) => h.inventoryHistory?.type?.code === code)?.inventoryHistory?.subType}人: ${row.histories
                    .filter((h) => h.inventoryHistory?.type?.code === code)
                    .map((h) => h.pickedBy)
                    .join('/')}`
                }
              },
              price: {
                change(row: EnocloudServiceDefinitions['ServiceGoodsDto']) {
                  this.form.goods.cal(row)
                }
              },
              chargingMethod: {
                change(row: EnocloudServiceDefinitions['ServiceGoodsDto']) {
                  row.discountRate = row.chargingMethod?.forceDiscountRate
                    ? row.chargingMethod.defaultDiscountRate
                    : this.form.data.customer?.serviceGoodsDiscountRate
                }
              },
              specification: {
                change(row: EnocloudServiceDefinitions['ServiceGoodsDto'], value: EnocloudServiceDefinitions['GoodsSpecificationDto']) {
                  row.goodsSpecification = { ...value, goods: cloneDeep(row.goodsSpecification?.goods) }
                }
              }
            },
            inspection: {
              collapse: true,
              children: {
                reworks: {
                  click(row: EnocloudServiceDefinitions['ServiceMaintenanceDto']) {
                    this.reworks.data = row
                    this.reworks.visible = true
                  }
                },
                rework: {
                  click(row: EnocloudServiceDefinitions['ServiceMaintenanceDto']) {
                    this.rework.data = row
                    this.rework.visible = true
                  }
                }
              }
            },
            fee: {
              management: {
                async click() {
                  await this.dirtyCheck('form')
                  this.management.visible = true
                }
              },
              tax: {
                async click() {
                  await this.dirtyCheck('form')
                  this.tax.visible = true
                }
              },
              other: {
                async click() {
                  await this.dirtyCheck('form')
                  this.other.visible = true
                }
              },
              expense: {
                async click() {
                  await this.dirtyCheck('form')
                  this.expense.visible = true
                }
              }
            },
            warrantyDate: {
              change() {
                this.form.warrantyDate.hint.value = ''
              },
              hint: {
                value: '',
                change(value: string) {
                  this.form.data.warrantyDate = dayjs().add(Number(value), 'month').format('YYYY-MM-DD')
                }
              }
            },
            discount: {
              click(type: string) {
                this.discount.type = type
                this.discount.maintenances = [...(this.form.data.maintenances ?? [])]
                this.discount.goods = [...(this.form.data.goods ?? [])]
                this.discount.visible = true
              },
              change() {}
            },
            coupon: {
              click() {}
            },
            suggestions: {
              show: false,
              required: false
            }
          }
        },
        dialog: {
          visible: false
        },
        detail: {
          visible: false,
          confirm(id: number | undefined) {
            this.form.data.id ??= id
            this.form.get()
          }
        },
        maintenanceEdit: {
          visible: false,
          children: {
            form: {
              data: formMaintenanceInit()
            }
          }
        },
        chargingMethodBatch: {
          visible: false,
          type: 'maintenance' as 'maintenance' | 'goods',
          confirm(value: EnocloudCommonDefinitions['LookupDto']) {
            const { type } = this.chargingMethodBatch
            if (type === 'maintenance') {
              for (const item of this.form.maintenances.selection.data) {
                const exist = this.form.data.maintenances?.find((m) => m.id === item.id || m.maintenance?.id === item.maintenance?.id)
                if (exist) exist.chargingMethod = value
              }
            } else if (type === 'goods') {
              for (const item of this.form.goods.selection.data) {
                const exist = this.form.data.goods?.find((m) => m.id === item.id || m.goodsSpecification?.id === item.goodsSpecification?.id)
                if (exist) exist.chargingMethod = value
              }
            }
          }
        },
        workingAssigneeBatch: {
          visible: false,
          type: 'maintenance' as 'maintenance' | 'goods',
          confirm(workingTeam: EnocloudServiceDefinitions['WorkingTeamDto'], assignees: any[]) {
            const { type } = this.workingAssigneeBatch
            if (type === 'maintenance') {
              for (const item of this.form.maintenances.selection.data) {
                const exist = this.form.data.maintenances?.find((m) => m.id === item.id || m.maintenance?.id === item.maintenance?.id)
                if (exist) {
                  exist.workingTeam = workingTeam
                  exist.assignees = assignees
                }
              }
            } else if (type === 'goods') {
            }
          }
        },
        goodsSelect: {
          visible: false,
          confirm(value: GoodsSelectionOption[]) {
            for (const item of value) {
              const exist = this.form.data.goods?.find((g) => g.goodsSpecification?.id === item.goodsSpecification?.id)

              if (exist) {
                exist.planCount = calculator.add(exist.count, item.count)
                exist.price = item.price
                this.form.goods.cal(exist)
              } else {
                this.form.goods.add({
                  goodsSpecification: Object.assign({}, item.goodsSpecification, {
                    goods: item.goods
                  }) as EnocloudServiceDefinitions['GoodsSpecificationDto'],
                  price: item.price
                })
              }
            }
          }
        },
        spraySurface: {
          visible: false,
          data: {
            codes: [] as string[],
            maintenances: [] as EnocloudServiceDefinitions['ServiceMaintenanceDto'][]
          },
          children: {
            codes: {
              async change() {
                const { maintenances, codes } = this.spraySurface.data
                const formMaintenacesCodes: string[] = maintenances.map((item) => item.maintenance!.spraySurface!) ?? []

                const append_codes = difference(codes, formMaintenacesCodes)
                const delete_codes = difference(formMaintenacesCodes, codes)

                for (const code of delete_codes) {
                  const index = findIndex(maintenances, ['maintenance.spraySurface', code])
                  if (index > -1) maintenances.splice(index, 1)
                }

                for (const code of append_codes) {
                  const res = await this.ajax('GET /enocloud/common/maintenance', { payload: { spraySurface: code } })
                  if (res.data[0]) {
                    const init = formMaintenanceInit({
                      maintenance: res.data[0],
                      name: res.data[0].name,
                      count: 1,
                      price: res.data[0].unitPrice,
                      warrantied: ['S', 'B'].includes(code.split('_')[1])
                        ? { code: 'N', type: '', message: '', description: '' }
                        : { code: 'Y', type: '', message: '', description: '' }
                    })
                    maintenances.push(init)
                  }
                }
              }
            },
            maintenances: {
              delete: {
                click(index: number) {
                  this.spraySurface.data.maintenances.splice(index, 1)
                  this.spraySurface.data.codes = this.spraySurface.data.maintenances.map((item) => item.maintenance?.spraySurface!)
                }
              },
              add: {
                click() {
                  this.spraySurface.data.maintenances.push(
                    formMaintenanceInit({ count: 1, warrantied: { code: 'Y', type: '', message: '', description: '' } })
                  )
                }
              },
              count: {
                change(row: EnocloudServiceDefinitions['ServiceMaintenanceDto'], value: number) {
                  row.count = calculator.div(value, row.maintenance?.square ?? 0, 2)
                }
              },
              change(row: EnocloudServiceDefinitions['ServiceMaintenanceDto']) {
                row.price = row.maintenance?.unitPrice ?? 0
              }
            },
            footer: {
              cancel: {
                click() {
                  this.spraySurface.visible = false
                }
              },
              confirm: {
                async click() {
                  const { maintenances = [] } = this.form.data
                  const formMaintenaces = maintenances?.filter((item) => !isNil(item.maintenance?.spraySurface))
                  const formMaintenacesCodes: string[] = map(formMaintenaces, 'maintenance.spraySurface')

                  const surfaceMaintenances = this.spraySurface.data.maintenances.filter((item) => !isNil(item.maintenance?.spraySurface))
                  const surfaceMaintenancesCodes = map(surfaceMaintenances, 'maintenance.spraySurface')

                  const append_codes = difference(surfaceMaintenancesCodes, formMaintenacesCodes)
                  const delete_codes = difference(formMaintenacesCodes, surfaceMaintenancesCodes)

                  for (const code of delete_codes) {
                    const index = findIndex(maintenances, ['maintenance.spraySurface', code])
                    if (index > -1) maintenances.splice(index, 1)
                  }

                  for (const code of append_codes) {
                    const item = find(surfaceMaintenances, ['maintenance.spraySurface', code])
                    if (item) this.form.maintenances.add(item)
                  }

                  this.spraySurface.footer.cancel.click()
                }
              }
            }
          }
        },
        rework: {
          visible: false,
          data: null as EnocloudServiceDefinitions['ServiceMaintenanceDto'] | null,
          async confirm(res: Definitions['ServiceMaintenanceInternalReworkDto']) {
            const maintenance = this.form.data.maintenances?.find((item) => item.id === this.rework.data?.id)
            if (!maintenance) return
            maintenance.reworks.push(res)
            await this.form.submit()
            this.form.get()
          }
        },
        reworks: {
          visible: false,
          data: null as EnocloudServiceDefinitions['ServiceMaintenanceDto'] | null,
          async confirm(res: EnocloudServiceDefinitions['ServiceMaintenanceDto']) {
            const maintenance = this.form.data.maintenances?.find((item) => item.id === this.reworks.data?.id)
            if (!maintenance) return
            maintenance.reworks = res.reworks
            await this.form.submit()
            this.form.get()
          }
        },
        reworkOrder: {
          visible: false,
          select(data: EnocloudServiceDefinitions['ServiceDto']) {
            this.reworkMaintenance.data = data
            this.reworkMaintenance.visible = true
          }
        },
        reworkMaintenance: {
          visible: false,
          data: {} as EnocloudServiceDefinitions['ServiceDto'],
          confirm() {
            this.form.get()
            this.reworkOrder.visible = false
          }
        },
        discount: {
          visible: false,
          type: 'A',
          maintenances: [] as EnocloudServiceDefinitions['ServiceMaintenanceDto'][],
          goods: [] as EnocloudServiceDefinitions['ServiceGoodsDto'][],
          async confirm(res: DiscountRes) {
            const { maintenances, goods } = res
            for (const item of maintenances) {
              const m = this.form.data.maintenances?.find((m) => m.id === item.id)
              if (m) m.discountRate = item.discountRate
            }
            for (const item of goods) {
              const g = this.form.data.goods?.find((m) => m.id === item.id)
              if (g) g.discountRate = item.discountRate
            }
            await this.form.submit()
            this.form.get()
          }
        },
        logs: {
          visible: false
        },
        maintain: {
          visible: false,
          confirm(mingjueMaintains: EnocloudCommonDefinitions['MingjueMaintainDto'][]) {
            for (const item of mingjueMaintains) {
              this.form.maintenances.add({ name: (item.keepModel ?? '') + (item.item ?? '') }, { inflated: false })
            }
          }
        },
        management: {
          visible: false
        },
        tax: {
          visible: false
        },
        other: {
          visible: false
        },
        expense: {
          visible: false
        },
        coupon: {
          visible: false
        },
        topup: {
          visible: false
        },
        deposit: {
          visible: false
        },
        blance: {
          visible: false
        },
        mall: {
          visible: false
        },
        vehicleRecord: {
          visible: false
        },
        customerRecord: {
          visible: false
        },
        outsource: {
          visible: false,
          data: formMaintenanceInit(),
          confirm() {
            const item = find(this.form.data.maintenances, ['maintenance.id', this.outsource.data.maintenance?.id])
            if (item) Object.assign(item, this.outsource.data)
          }
        },
        approval: {
          visible: false
        }
      }
    }
  })
}
